<template>
  <div >
    <!--<TitleComponent class="primary--text text-uppercase text-center" :title="`Maps`"/>-->
    <PageHeader pagetitle="Gallery" :img="img" />   
    <!--<v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">-->
    
  <v-container fluid>
 
     <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
      <swiper-slide
        v-for="(item, i) in this.TableData"   
        :key="i"
      
      >
      
        <v-card
          class="mx-auto"
          :max-width="$vuetify.breakpoint.lgAndUp ? 400 : 175"
          :max-height="400"
          elevation="10"
        >
        

          <v-card-title class="text-subtitle-1 font-weight-bold " >
          <a @click="$router.push('/viewcontent/'+item.ID+'/galleries')">
            {{item.Name}}
          </a>
          </v-card-title> 
          <v-card-subtitle class="text-caption font-weight-bold" >
            {{item.Content}}
          </v-card-subtitle> 

           
          <EnlargeableImage
            :src="$api_url+item.Url" 
            :src_large="$api_url+item.Url" 
            :src_height="$vuetify.breakpoint.lgAndUp ? 180 : 90"
            :src_width="$vuetify.breakpoint.lgAndUp ? 350 : 175"
          />
         
          <!-- 
          <v-img
              class=""
              :height="$vuetify.breakpoint.lgAndUp ? 180 : 90"
              :width="$vuetify.breakpoint.lgAndUp ? 350 : 175"
              :src="$api_url+item.Url"
              :lazy-src="$api_url+item.Url"
              aspect-ratio=""
              :alt="item.Name"
              :title="item.Name"
          >
          </v-img>
           -->  
  
      </v-card>
    
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
 
   <v-divider></v-divider>   
 
  </v-container>

    <Footer v-if="this.$route.name !== 'Home'"> </Footer>
  </div>
</template>

<script>
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

import axios from 'axios' 
//import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'

import EnlargeableImage from '@/components/EnlargeableImage';
import PageHeader from '@/components/PageHeader'


export default {

  name : 'Gallery',
  components: {
    //TitleComponent,
    Footer,
    EnlargeableImage,
    PageHeader,
    Swiper,
    SwiperSlide,
  },
 
  data () {
    return {
      show: false,
      img : 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
      items:[],
      TableData:[],
      TableCategoryData:[],
      SelectedCategory:'',
      swiperOptions: {
          slidesPerView: (this.$vuetify.breakpoint.mdAndUp ? 5 : 1 ),
          spaceBetween: 10,
          slidesPerColumn: (this.$vuetify.breakpoint.mdAndUp ? 3 : 1 ),
          pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
      }

    }

    },

   
    mounted(){
      //alert(this.items);
      this.TableData=this.getAllRecords(`/getAllRecords/galleries/where ID > 0 /CreatedDate desc`,`galleries`);
      //this.displayTableimages(this.$route.params.ID)
     //this.swiper.slideTo(3, 1000, false) 

    },  

    updated(){
      
     
    },

    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },

    methods:{

     
      toggleshow(item){
        item.Show=!item.Show
      },
      formatData(value){
        //alert(value);!isNaN(Date.parse(value)) 
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
            
            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){
            
            return this.formatDate(value);
        }
        else{
            return value
        }
      },
      getAllRecords:function(query,table){
      axios.get( this.$api_url+query,{
          params: {}
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              if(table=='galleries'){
                this.TableData=response.data;
              }
              
              
            }
      })
      .catch(error => { console.log(error)})
    },  

     async displayTableimages(id){
        //alert(projectid);
       
        try {
          const { data } = await axios.get(
           this.$api_url+`/displayTableimages/${this.$route.params.Table.toLowerCase()}/${id}`,
            );
          //alert(data);
          this.ImagesData = data;
          //alert(this.ImagesData);
          console.log(this.ImagesData);
          
          
        } catch (error) {
          console.log(error);
        }
    },  

  }
}

</script>

<style lang="scss" >

</style>